
import { useAppStore } from '@bd/agent/store'
import {
  DashboardOfferDescription,
  DashboardOfferDetails,
  DashboardOfferGallery,
  DashboardOfferMap,
  DashboardOfferSidebar,
  DashboardOfferFooter,
  DashboardOfferUser,
  OfferFooterButtons,
  EmptyState,
  PageContent,
  useResourceDataState,
  useTextOrPlaceholder,
  UserType,
  DisplayedOfferUsers,
} from '@bd/components'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'DashboardOffer',
  components: {
    DashboardOfferGallery,
    DashboardOfferMap,
    DashboardOfferSidebar,
    DashboardOfferDetails,
    DashboardOfferDescription,
    DashboardOfferUser,
    DashboardOfferFooter,
    PageContent,
    ProgressSpinner,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()

    const offer = computed(() => store.state.offers?.offerDetails)
    const { isLoading, isEmpty } = useResourceDataState(offer)

    const title = computed(() => offer.value?.title)
    const titleValue = useTextOrPlaceholder(title, {
      placeholder: t('offerDetails.no_title'),
    })

    const displayedFooterButtons: OfferFooterButtons[] = ['openProposal']
    const displayedUsers: DisplayedOfferUsers[] = ['seller', 'agent']

    const openProposalModal = () => {
      store.dispatch('dialogVisibility', {
        proposalModal: true,
      })
    }

    const loadOffer = (id: number) => {
      store.commit('offers/SET_OFFER', undefined)
      store.dispatch('offers/fetchOffer', id)
    }

    const isGalleryFullscreen = ref(false)
    const onGalleryFullscreenToggle = (isFullscreen: boolean) => {
      isGalleryFullscreen.value = isFullscreen
    }

    loadOffer(+route.params.id)

    return {
      t,
      offer,
      openProposalModal,
      isLoading,
      isEmpty,
      titleValue,
      displayedFooterButtons,
      isGalleryFullscreen,
      onGalleryFullscreenToggle,
      UserType,
      displayedUsers,
    }
  },
})
