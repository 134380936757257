<template>
  <PageContent
    class="offer-content"
    :class="{ 'is-loading': isLoading, 'is-empty': isEmpty }"
    :showMobileHeader="!isGalleryFullscreen"
    :headerLabel="t('offers.details')"
    :backRoute="{ name: 'Dashboard' }"
  >
    <template v-if="!isLoading && !isEmpty">
      <div class="content-header-wrapper">
        <div class="container-fluid container-lg py-3">
          <div class="content-header">
            <div class="back-button">
              <Button
                @click="$router.back()"
                icon="pi pi-chevron-left"
                class="p-button-secondary p-button-circle"
              />
            </div>
            <h1 class="offer-title text-wrap-2">{{ titleValue }}</h1>
            <DashboardOfferUser
              v-if="displayedUsers.includes('agent')"
              :userData="offer.offerAgentDto"
              :typeOfUser="UserType.Agent"
              class="offer-keeper"
            />
          </div>
        </div>
      </div>

      <div class="container-fluid container-lg dashboard-offer-padding">
        <div class="content-wrapper">
          <div class="main-column">
            <DashboardOfferGallery
              :offerDetails="offer"
              :showStatistics="true"
              @toggleFullscreen="onGalleryFullscreenToggle"
            />
            <div>
              <h3>{{ t('location') }}</h3>
              <DashboardOfferMap :offerDetails="offer" />
            </div>
            <div>
              <h3>{{ t('userType.seller') }}</h3>
              <DashboardOfferUser
                :userData="offer.offerSellerDto"
                :typeOfUser="UserType.Seller"
                desktopVersion
                class="inner-margin"
              />
            </div>
            <div class="d-md-none">
              <h3>{{ t('userType.agent') }}</h3>
              <DashboardOfferUser
                :userData="offer.offerAgentDto"
                :typeOfUser="UserType.Agent"
                class="inner-margin"
              />
            </div>
            <div>
              <h3>{{ t('property_description') }}</h3>
              <DashboardOfferDescription
                :description="offer?.description || ''"
              />
            </div>
            <div>
              <h3>{{ t('more_details') }}</h3>
              <DashboardOfferDetails :offerDetails="offer" />
            </div>
          </div>
          <DashboardOfferSidebar
            :offerDetails="offer"
            :displayedButtons="displayedFooterButtons"
            :displayedUsers="displayedUsers"
            :showStatistics="true"
            @openProposal="openProposalModal"
            @toggleGalleryFullscreen="onGalleryFullscreenToggle"
          />
        </div>
      </div>
    </template>
    <template v-if="isLoading">
      <div class="progress-container">
        <ProgressSpinner />
      </div>
    </template>
    <template v-if="isEmpty && !isLoading">
      <EmptyState :title="t('offerDetails.empty_state.title')">
        <Button
          icon="pi pi-arrow-left"
          class="p-button-primary px-3"
          :label="t('offerDetails.empty_state.back')"
          @click="$router.push({ name: 'Dashboard' })"
        />
      </EmptyState>
    </template>
    <DashboardOfferFooter
      v-if="!isGalleryFullscreen"
      class="mobile-offer-footer"
      :displayedButtons="displayedFooterButtons"
      :offerDetails="offer"
      @openProposal="openProposalModal"
    />
  </PageContent>
</template>

<script lang="ts">
import { useAppStore } from '@bd/agent/store'
import {
  DashboardOfferDescription,
  DashboardOfferDetails,
  DashboardOfferGallery,
  DashboardOfferMap,
  DashboardOfferSidebar,
  DashboardOfferFooter,
  DashboardOfferUser,
  OfferFooterButtons,
  EmptyState,
  PageContent,
  useResourceDataState,
  useTextOrPlaceholder,
  UserType,
  DisplayedOfferUsers,
} from '@bd/components'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'DashboardOffer',
  components: {
    DashboardOfferGallery,
    DashboardOfferMap,
    DashboardOfferSidebar,
    DashboardOfferDetails,
    DashboardOfferDescription,
    DashboardOfferUser,
    DashboardOfferFooter,
    PageContent,
    ProgressSpinner,
    EmptyState,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()

    const offer = computed(() => store.state.offers?.offerDetails)
    const { isLoading, isEmpty } = useResourceDataState(offer)

    const title = computed(() => offer.value?.title)
    const titleValue = useTextOrPlaceholder(title, {
      placeholder: t('offerDetails.no_title'),
    })

    const displayedFooterButtons: OfferFooterButtons[] = ['openProposal']
    const displayedUsers: DisplayedOfferUsers[] = ['seller', 'agent']

    const openProposalModal = () => {
      store.dispatch('dialogVisibility', {
        proposalModal: true,
      })
    }

    const loadOffer = (id: number) => {
      store.commit('offers/SET_OFFER', undefined)
      store.dispatch('offers/fetchOffer', id)
    }

    const isGalleryFullscreen = ref(false)
    const onGalleryFullscreenToggle = (isFullscreen: boolean) => {
      isGalleryFullscreen.value = isFullscreen
    }

    loadOffer(+route.params.id)

    return {
      t,
      offer,
      openProposalModal,
      isLoading,
      isEmpty,
      titleValue,
      displayedFooterButtons,
      isGalleryFullscreen,
      onGalleryFullscreenToggle,
      UserType,
      displayedUsers,
    }
  },
})
</script>

<style lang="scss" scoped>
h1,
h3 {
  font-size: 1.4rem;
  font-weight: 600;
  color: $port-gore;
  display: flex;
  align-items: center;
  height: 80px;
}

.offer-title {
  line-height: normal;
  flex: 1;
  height: 100%;
  margin-right: 1rem;
  min-height: 30px;

  @include breakpoint-down(md) {
    display: none;
  }
}

.offer-keeper {
  flex: 0 0 28%;

  @include breakpoint-down(md) {
    display: none;
  }
}

.dashboard-offer-padding {
  padding: 0;
  @include breakpoint-up(md) {
    padding: 1rem;
  }
}

.content-header-wrapper {
  background: $alabaster;
  @include breakpoint-down(md) {
    display: none;
  }
}

.content-wrapper {
  @include breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }
  .main-column {
    @include breakpoint-down(md) {
      display: none;
    }
    width: 65%;
    display: flex;
    flex-direction: column;
  }
}

.main-column {
  position: relative;
}

.content-header {
  display: flex;
  align-items: center;
  position: relative;
}

.back-button {
  position: absolute;
  left: -4rem;
}

:deep(.offer-content) {
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;

  @include breakpoint-down(md) {
    height: calc(
      100vh - #{$mobile-header-height} - #{$dashboard-offer-footer-height}
    );
  }

  &.is-loading,
  &.is-empty {
    height: calc(100vh - #{$top-navigation-height});
    @include breakpoint-down(md) {
      height: 100vh;
    }
  }
}

.mobile-offer-footer {
  @include breakpoint-up(md) {
    display: none;
  }
}
</style>
